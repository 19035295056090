var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[(_vm.warning)?_c('div',{staticClass:"notice"},[_vm._v(_vm._s(_vm.warning.info))]):_vm._e(),(_vm.need)?_c('div',{staticClass:"cms"},[_c('a',{staticClass:"action",on:{"click":_vm.involve}},[_vm._v("发送坚守者平台小程序")])]):_vm._e(),(_vm.nomral)?_c('div',[_c('div',{staticClass:"cms"},[_c('span',{staticClass:"caption"},[_vm._v("客户信息(坚守者)")]),_c('ul',[_c('li',[_c('span',{staticClass:"item"},[_vm._v("激活状态")]),_c('span',[_c('span',{staticClass:"content"},[_vm._v(_vm._s(_vm.statusText))]),(
								_vm.model.platform && _vm.model.platform.status == 0
							)?_c('a',{staticClass:"action",on:{"click":_vm.activate}},[_vm._v("激活")]):_vm._e(),(
								_vm.model.platform && _vm.model.platform.status == 1
							)?_c('a',{staticClass:"action",on:{"click":_vm.unbind}},[_vm._v("解绑")]):_vm._e()])]),(_vm.model.system && _vm.model.system.success_time)?[_c('li',[_c('span',{staticClass:"item"},[_vm._v("坚守者")]),_c('span',{staticClass:"content"},[_vm._v(_vm._s(_vm.keeperText))])]),_c('li',[_c('span',{staticClass:"item"},[_vm._v("加入计划时间")]),_c('span',{staticClass:"content"},[_vm._v(_vm._s(_vm.datetime))])]),_c('li',[_c('span',{staticClass:"item"},[_vm._v("加入计划时所占份额")]),_c('span',{staticClass:"content"},[_vm._v(_vm._s(_vm.model.system && _vm.model.system.account))])]),_c('li',[_c('span',{staticClass:"item"},[_vm._v("当前份额")]),_c('span',{staticClass:"content"},[_vm._v(_vm._s(_vm.model.system && _vm.model.system.current_account))])])]:_vm._e(),_c('li',[_c('span',{staticClass:"item"},[_vm._v("债转市场")]),_c('span',[_c('span',{staticClass:"content"},[_vm._v(_vm._s(_vm.marketFlag))]),(
								_vm.model.platform &&
									_vm.model.platform.activeMarket == 0
							)?_c('a',{staticClass:"action",on:{"click":_vm.allowM}},[_vm._v("开启")]):_vm._e(),(
								_vm.model.platform &&
									_vm.model.platform.activeMarket == 1
							)?_c('a',{staticClass:"action",on:{"click":_vm.forbidM}},[_vm._v("关闭")]):_vm._e()])])],2)]),_c('div',{staticClass:"cms"},[_c('span',{staticClass:"caption"},[_vm._v("账户信息(爱投资)")]),_c('ul',[_c('li',[_c('span',{staticClass:"item"},[_vm._v("总资产(元)")]),_c('span',{staticClass:"content"},[_vm._v(_vm._s(_vm.model.system && _vm.model.system.total_money))])]),_c('li',[_c('span',{staticClass:"item"},[_vm._v("已赚取(元)")]),_c('span',{staticClass:"content"},[_vm._v(_vm._s(_vm.model.system && _vm.model.system.income_money))])]),_c('li',[_c('span',{staticClass:"item"},[_vm._v("余额(元)")]),_c('span',{staticClass:"content"},[_vm._v(_vm._s(_vm.model.system && _vm.model.system.use_money))])]),_c('li',[_c('span',{staticClass:"item"},[_vm._v("充提差(元)")]),_c('span',{staticClass:"content"},[_vm._v(_vm._s(_vm.model.system && _vm.model.system.difference))])]),_c('li',[_c('span',{staticClass:"item"},[_vm._v("姓名")]),_c('span',{staticClass:"content"},[_vm._v(_vm._s(_vm.model.system && _vm.model.system.realname))])]),_c('li',[_c('span',{staticClass:"item"},[_vm._v("身份证号码")]),_c('span',{staticClass:"content"},[_vm._v(_vm._s(_vm.model.system && _vm.model.system.card_id))])]),_c('li',[_c('span',{staticClass:"item"},[_vm._v("注册手机号")]),_c('span',{staticClass:"content"},[_vm._v(_vm._s(_vm.model.system && _vm.model.system.phone))])])])]),_c('div',{staticClass:"cms"},[_c('span',{staticClass:"caption"},[_vm._v("商城信息(冰田)")]),_c('ul',[_c('li',[_c('span',{staticClass:"item"},[_vm._v("债转商城")]),_c('span',[_c('span',{staticClass:"content"},[_vm._v(_vm._s(_vm.mallFlag))]),(
								_vm.model.platform &&
									_vm.model.platform.activeShop == 0
							)?_c('a',{staticClass:"action",on:{"click":_vm.allow}},[_vm._v("开启")]):_vm._e(),(
								_vm.model.platform &&
									_vm.model.platform.activeShop == 1
							)?_c('a',{staticClass:"action",on:{"click":_vm.forbid}},[_vm._v("关闭")]):_vm._e()])]),_c('li',[_c('span',{staticClass:"item"},[_vm._v("消费债权")]),_c('span',{staticClass:"content"},[_vm._v(_vm._s(_vm.model.system && _vm.model.system.shop_debt))])]),_c('li',[_c('span',{staticClass:"item"},[_vm._v("消费现金")]),_c('span',{staticClass:"content"},[_vm._v(_vm._s(_vm.model.system && _vm.model.system.shop_paymoney))])])])]),_c('div',{staticClass:"cms"},[_c('ul',[_c('li',[_c('span',{staticClass:"item"},[_vm._v("客服数量")]),_c('span',{staticClass:"content"},[_vm._v(_vm._s(_vm.model.platform && _vm.model.platform.waiterCount))])]),_c('li',[_c('span',{staticClass:"item"},[_vm._v("最后登录")]),_c('span',{staticClass:"content"},[_vm._v(_vm._s(_vm.lastLogin))])])])]),(_vm.model1.waiters1)?_c('div',{staticClass:"cms"},[_c('span',{staticClass:"caption"},[_vm._v("冰田客服信息("+_vm._s(_vm.model1.waiters1)+")")]),_c('div',{domProps:{"innerHTML":_vm._s(_vm.waiterList1())}})]):_vm._e(),(_vm.model1.waiters)?_c('div',{staticClass:"cms"},[_c('span',{staticClass:"caption"},[_vm._v("坚守者客服信息("+_vm._s(_vm.model1.waiters)+")")]),_c('div',{domProps:{"innerHTML":_vm._s(_vm.waiterList())}})]):_vm._e()]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }