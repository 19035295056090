import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import App from './index.vue';

Vue.config.productionTip = false;
Vue.use(VueAxios, axios);
axios.defaults.withCredentials = true;
axios.defaults.baseURL = 'https://api.ce-am.com/qyapi';
// axios.defaults.baseURL = 'https://api.subyke.com/qyapi';

new Vue({
	render: (h) => h(App),
}).$mount('#app');
