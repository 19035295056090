<style lang="scss">
.main {
	@extend .center, .content;
	padding-bottom: 10px;
}

.cms {
	@extend .content;
	margin-top: 5px;
	padding: 10px 5px;

	.caption {
		display: inline-block;
		margin-bottom: 10px;
	}

	.action {
		background-color: $nav-color;
		border-radius: 3px;
		padding: 5px 10px;
		color: $content-color;
		margin-left: 10px;
	}
}

.caption {
	font-size: $large-size;
	font-weight: $caption-weight;
	color: $caption-color;
}

.waiter {
	margin-right: 10px;
}

.tag {
	margin: 0 5px;
	color: #ccc;
	// background-color: #f5f5f5;
}

li {
	@extend .row;
	margin-bottom: 8px;

	.item {
		font-size: $middle-size;
		font-weight: $caption-weight;
		color: $text-color;
		margin-right: 30px;
		width: 100px;
	}

	.content {
		font-size: $middle-size;
	}
}

.notice {
	@extend .content;
	font-size: $large-size;
	color: $warning-color;
}
</style>
<template>
	<div id="app">
		<div class="notice" v-if="warning">{{ warning.info }}</div>
		<div class="cms" v-if="need">
			<a class="action" @click="involve">发送坚守者平台小程序</a>
		</div>
		<div v-if="nomral">
			<div class="cms">
				<span class="caption">客户信息(坚守者)</span>
				<ul>
					<li>
						<span class="item">激活状态</span>
						<span>
							<span class="content">{{ statusText }}</span>
							<a
								class="action"
								v-if="
									model.platform && model.platform.status == 0
								"
								@click="activate"
								>激活</a
							>
							<a
								class="action"
								v-if="
									model.platform && model.platform.status == 1
								"
								@click="unbind"
								>解绑</a
							>
						</span>
					</li>
					<template v-if="model.system && model.system.success_time">
						<li>
							<span class="item">坚守者</span
							><span class="content">{{ keeperText }}</span>
						</li>
						<li>
							<span class="item">加入计划时间</span
							><span class="content">{{ datetime }}</span>
						</li>
						<li>
							<span class="item">加入计划时所占份额</span
							><span class="content">{{
								model.system && model.system.account
							}}</span>
						</li>
						<li>
							<span class="item">当前份额</span
							><span class="content">{{
								model.system && model.system.current_account
							}}</span>
						</li>
					</template>
					<li>
						<span class="item">债转市场</span>
						<span>
							<span class="content">{{ marketFlag }}</span>
							<a
								class="action"
								v-if="
									model.platform &&
										model.platform.activeMarket == 0
								"
								@click="allowM"
								>开启</a
							>
							<a
								class="action"
								v-if="
									model.platform &&
										model.platform.activeMarket == 1
								"
								@click="forbidM"
								>关闭</a
							>
						</span>
					</li>
				</ul>
			</div>
			<div class="cms">
				<span class="caption">账户信息(爱投资)</span>
				<ul>
					<li>
						<span class="item">总资产(元)</span
						><span class="content">{{
							model.system && model.system.total_money
						}}</span>
					</li>
					<li>
						<span class="item">已赚取(元)</span
						><span class="content">{{
							model.system && model.system.income_money
						}}</span>
					</li>
					<li>
						<span class="item">余额(元)</span
						><span class="content">{{
							model.system && model.system.use_money
						}}</span>
					</li>
					<li>
						<span class="item">充提差(元)</span
						><span class="content">{{
							model.system && model.system.difference
						}}</span>
					</li>
					<li>
						<span class="item">姓名</span
						><span class="content">{{
							model.system && model.system.realname
						}}</span>
					</li>
					<li>
						<span class="item">身份证号码</span
						><span class="content">{{
							model.system && model.system.card_id
						}}</span>
					</li>
					<li>
						<span class="item">注册手机号</span
						><span class="content">{{
							model.system && model.system.phone
						}}</span>
					</li>
				</ul>
			</div>
			<div class="cms">
				<span class="caption">商城信息(冰田)</span>
				<ul>
					<li>
						<span class="item">债转商城</span>
						<span>
							<span class="content">{{ mallFlag }}</span>
							<a
								class="action"
								v-if="
									model.platform &&
										model.platform.activeShop == 0
								"
								@click="allow"
								>开启</a
							>
							<a
								class="action"
								v-if="
									model.platform &&
										model.platform.activeShop == 1
								"
								@click="forbid"
								>关闭</a
							>
						</span>
					</li>
					<li>
						<span class="item">消费债权</span
						><span class="content">{{
							model.system && model.system.shop_debt
						}}</span>
					</li>
					<li>
						<span class="item">消费现金</span
						><span class="content">{{
							model.system && model.system.shop_paymoney
						}}</span>
					</li>
				</ul>
			</div>
			<div class="cms">
				<!-- <span class="caption">企业微信</span> -->
				<ul>
					<li>
						<span class="item">客服数量</span
						><span class="content">{{
							model.platform && model.platform.waiterCount
						}}</span>
					</li>
					<li>
						<span class="item">最后登录</span
						><span class="content">{{ lastLogin }}</span>
					</li>
				</ul>
			</div>
			<div class="cms" v-if="model1.waiters1">
				<span class="caption">冰田客服信息({{ model1.waiters1 }})</span>
				<div v-html="waiterList1()"></div>
			</div>
			<div class="cms" v-if="model1.waiters">
				<span class="caption"
					>坚守者客服信息({{ model1.waiters }})</span
				>
				<div v-html="waiterList()"></div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: 'index',
	data() {
		return {
			jsApiList: ['getCurExternalContact', 'sendChatMessage'],
			model: {},
			model1: {},
			nomral: false,
			need: false,
			warning: null,
		};
	},

	computed: {
		statusText: function() {
			if (this.model && this.model.platform)
				switch (this.model.platform.status) {
					case -1:
						return '未绑定';
					case 0:
						return '已绑定未激活';
					case 1:
						return '已激活';
				}
			return '';
		},

		keeperText: function() {
			if (this.model && this.model.system)
				switch (this.model.system.status) {
					case 0:
						return '否';
					case 1:
						return '一期坚守者';
					case 2:
						return '二期坚守者';
				}
			return '';
		},

		mallFlag: function() {
			if (this.model && this.model.platform)
				switch (this.model.platform.activeShop) {
					case 0:
						return '未开启';
					case 1:
						return '已开启';
				}
			return '';
		},

		marketFlag: function() {
			if (this.model && this.model.platform)
				switch (this.model.platform.activeMarket) {
					case 0:
						return '未开启';
					case 1:
						return '已开启';
				}
			return '';
		},

		datetime: function() {
			if (!this.model.system.success_time) return '';
			var d = new Date(this.model.system.success_time * 1000);
			return [d.getFullYear(), d.getMonth() + 1, d.getDate()].join('-');
		},

		lastLogin: function() {
			if (!this.model.platform.loginTime) return '';
			var d = new Date(this.model.platform.loginTime * 1000);
			return (
				[d.getFullYear(), d.getMonth() + 1, d.getDate()].join('-') +
				' ' +
				[d.getHours(), d.getMinutes() + 1, d.getSeconds()].join(':')
			);
		},
	},

	async mounted() {
		let result;
		try {
			result = await this.inject();
			console.log('注入agentConfig成功', result);
		} catch (error) {
			console.log('注入agentConfig失败', error);
			this.warn(error);
			return;
		}
		try {
			result = await this.invoke();
			console.log('获取客户OK', result.userId);
		} catch (error) {
			console.log('获取客户不ok', error);
			this.warn(error);
			return;
		}
		try {
			result = await this.axios.get('/index/getUserInfo', {
				params: {
					user_id: result.userId,
				},
			});
			result = result.data;
			if (result.code == 0) {
				this.model = result.data;
				this.nomral = true;
				console.log('获取用户信息成功:', this.model);
			} else {
				this.warn(result.info);
			}
		} catch (error) {
			console.log('获取用户信息失败:', error);
			this.warn(error);
		}

		if (!this.model.platform || !this.model.platform.client_id) return;

		try {
			result = await this.axios.get(
				'https://assistant.easy-more.com/api/user/info1',
				{
					params: {
						id: this.model.platform.client_id,
					},
				}
			);
			result = result.data;
			console.log('用户信息', result);
			if (result.code === 0) {
				this.model1 = result.data;
				console.log('获取用户信息成功:', this.model1);
			} else {
				this.warn(result.info);
			}
		} catch (error) {
			console.log('获取用户信息成功:', error);
			this.warn(error);
		}
	},

	methods: {
		inject() {
			return new Promise((resolve, reject) => {
				const config = Object.assign(
					{
						jsApiList: this.jsApiList,
						success: resolve,
						fail: reject,
					},
					window.CONFIG
				);
				console.log('agentConfig内容:', config);
				window.wx.agentConfig(config);
			});
		},

		invoke() {
			return new Promise((resolve, reject) => {
				window.wx.invoke('getCurExternalContact', {}, function(result) {
					if (result.err_msg == 'getCurExternalContact:ok')
						resolve(result);
					else reject(result);
				});
			});
		},

		async activate() {
			if (!confirm('是否确定激活？')) return;
			try {
				let result = await this.axios.post('/index/activeUser', {
					client_id: this.model.platform.client_id,
				});
				if (result.data.code == 0) {
					this.model.platform.status = 1;
					console.log('激活用户成功:', result.data);
				} else console.log('激活用户失败:', result.data);
			} catch (error) {
				console.log('激活用户失败:', error);
			}
		},

		async unbind() {
			console.log('增加确认');
			if (!confirm('是否确定解绑？')) return;
			console.log('nima');
			try {
				let result = await this.axios.post('/index/unbindUser', {
					client_id: this.model.platform.client_id,
				});
				if (result.data.code == 0) {
					this.model.platform.status = -1;
					console.log('解绑用户成功:', result.data);
				} else console.log('解绑用户失败:', result.data);
			} catch (error) {
				console.log('解绑用户失败:', error);
			}
		},

		async allow() {
			if (!this.model.platform || this.model.platform.status !== 1) {
				alert('非激活用户不能开启');
				return;
			}
			if (!this.model.system || !this.model.system.success_time) {
				alert('非坚守者用户不能开启');
				return;
			}
			if (!confirm('是否确定准入？')) return;
			try {
				let result = await this.axios.post('/index/activeShop', {
					client_id: this.model.platform.client_id,
				});
				if (result.data.code == 0) {
					this.model.platform.activeShop = 1;
					console.log('开启成功:', result.data);
				} else console.log('开启失败:', result.data);
			} catch (error) {
				console.log('开启失败:', error);
			}
		},

		async forbid() {
			console.log('增加确认');
			if (!confirm('是否确定禁止？')) return;
			try {
				let result = await this.axios.post('/index/unactiveShop', {
					client_id: this.model.platform.client_id,
				});
				if (result.data.code == 0) {
					this.model.platform.activeShop = 0;
					console.log('禁止成功:', result.data);
				} else console.log('禁止失败:', result.data);
			} catch (error) {
				console.log('禁止失败:', error);
			}
		},

		async allowM() {
			if (!this.model.platform || this.model.platform.status !== 1) {
				alert('非激活用户不能开启');
				return;
			}
			if (!this.model.system || !this.model.system.success_time) {
				alert('非坚守者用户不能开启');
				return;
			}
			if (!confirm('是否确定准入？')) return;
			try {
				let result = await this.axios.post('/index/activeMarket', {
					client_id: this.model.platform.client_id,
				});
				if (result.data.code == 0) {
					this.model.platform.activeMarket = 1;
					console.log('开启成功:', result.data);
				} else console.log('开启失败:', result.data);
			} catch (error) {
				console.log('开启失败:', error);
			}
		},

		async forbidM() {
			console.log('增加确认');
			if (!confirm('是否确定禁止？')) return;
			try {
				let result = await this.axios.post('/index/unactiveMarket', {
					client_id: this.model.platform.client_id,
				});
				if (result.data.code == 0) {
					this.model.platform.activeMarket = 0;
					console.log('禁止成功:', result.data);
				} else console.log('禁止失败:', result.data);
			} catch (error) {
				console.log('禁止失败:', error);
			}
		},

		waiterList() {
			if (this.model1.waiters == 0)
				return '<span class="hilite">没有客服</span>';
			return this.model1.waiter
				.split('||')
				.map((item) =>
					item
						.split(':')
						.map((element, index) => {
							if (index == 0)
								return `<span class="waiter">${element}</span>`;
							else {
								if (element)
									return element
										.split('|')
										.map(
											(cell) =>
												`<span class="tag">${cell}</span>`
										)
										.join('');
								else return '';
							}
						})
						.join('')
				)
				.join('<br/>');
		},

		waiterList1() {
			if (this.model1.waiters1 == 0)
				return '<span class="hilite">没有客服</span>';
			return this.model1.waiter1
				.split('||')
				.map((item) =>
					item
						.split(':')
						.map((element, index) => {
							if (index == 0)
								return `<span class="waiter">${element}</span>`;
							else {
								if (element)
									return element
										.split('|')
										.map(
											(cell) =>
												`<span class="tag">${cell}</span>`
										)
										.join('');
								else return '';
							}
						})
						.join('')
				)
				.join('<br/>');
		},

		warn(info) {
			this.warning = { info };
			this.need = true;
		},

		involve() {
			window.wx.invoke(
				'sendChatMessage',
				{
					msgtype: 'miniprogram',
					miniprogram: {
						appid: 'wx5ea72b9793d8d0c7',
						title: '中锦坚守者服务平台',
						imgUrl: 'https://camp.ce-am.com/logo.jpeg',
						page: '/pages/index/index.html',
					},
				},
				function(res) {
					console.log('发送结果', res);
				}
			);
		},
	},
};
</script>
